














import Component from 'vue-class-component';

import { BaseVue } from '../../BaseVue';
import Invoicing from '../../components/org/Invoicing.vue';
@Component({
  components: {
    Invoicing,
  },
})
export default class InvoicingClass extends BaseVue {
  public selectedTab = 0;
}
